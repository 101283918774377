import React from 'react'
import { Link } from 'react-router-dom'

import './Funds.sass'
import ports from 'src/static/images/ports40.png'

const Funds = () => {

  return (
    <div id="funds">
      <header id="masthead">
        <div className="container">
          <Link to="/">
            <span className="icon icon-left-arrow" />
            <div className="logo" />
          </Link>
        </div>
      </header>
      <section id="content">
        <section id="ports">
          <a target="_blank" rel="noopener noreferrer" href="https://www.puertos.es/es-es/Paginas/ports40.aspx">
            <img className="fund-image" src={ports} alt="ports 4.0" />
          </a>
        </section>
      </section>


      <section id="footer">
        <footer className="footer-style">
          <div className="container">
            <div className="left">
              <div className="logo" />
            </div>
          </div>
        </footer>
      </section>
    </div>
  )
}

export default Funds
