import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Funds from './Funds/Funds'
import Home from './Home/Home'

const App = () =>
  <Switch>
    <Route path="/" exact>
      <Home />
    </Route>
    <Route path="/fondos-publicos" exact>
      <Funds />
    </Route>
  </Switch>

export default App
