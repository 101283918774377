import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import MarkdownMessage from '@gluedigital/markdown-message'

import './Home.sass'

import intro from 'src/static/images/intro-phone.png'
import analyze from 'src/static/images/analyze.png'
import aggregate from 'src/static/images/aggregate.png'
import setting from 'src/static/images/setting.png'
import financing from 'src/static/images/financing.png'

const Home = () => {

  const options = ['Energy Consumer', 'Energy Producer', 'Investor', 'Other']

  const [isSuccess, setSuccess] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    const role = e.target.optionSelect.value
    const name = e.target.name.value
    const mail = e.target.mail.value

    console.log(role)
    console.log(name)
    console.log(mail)

    fetch('/api/contact', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ role, name, mail })
    })
      .then(res => res.json())
      .then(res => {
        if (res.success) {
          setSuccess(true)
        } else {
          throw new Error()
        }
      })
      .catch(() => alert('Something went wrong. Please, try again.'))
  }

  const [isChange, setChange] = useState(false)

  const handleChange = e => {
    setChange(true)
  }

  let form =
    <form
      className="contact-form"
      onSubmit={handleSubmit}
      onChange={handleChange}
      method="post"
      encType="text/plain"
    >
      <h1 className="contact-form-title">
        <FormattedMessage id="home.form-title" />
      </h1>
      <label className="label-style label-title">
        <span className="span-title"><FormattedMessage id="home.form-select-title" /></span>
        <div className="select-wrapper">
          <select className="select-css" name="optionSelect">
            {options.map((option, i) => {
              return (
                <FormattedMessage key={i} id={`home.form-select-option-${i + 1}`}>
                  {text => <option value={option}>{text}</option>}
                </FormattedMessage>
              )
            })}
          </select>
          <span className="icon icon_angle-down" />
        </div>
      </label>
      <label className="label-style label-text">
        <span className="span-text"><FormattedMessage id="home.form-text-1" /></span>
        <FormattedMessage id="placeholder.type">
          {
            text =>
              <input
                className="input-style"
                type="text"
                name="name"
                placeholder={text}
                required
              />
          }
        </FormattedMessage>
      </label>
      <label className="label-style label-text">
        <span className="span-text"><FormattedMessage id="home.form-text-2" /></span>
        <FormattedMessage id="placeholder.type">
          {
            text =>
              <input
                className="input-style"
                type="email"
                name="mail"
                placeholder={text}
                required
              />
          }
        </FormattedMessage>
      </label>
      <button className="button" type="submit" disabled>
        <FormattedMessage id="home.form-button" />
      </button>
    </form>

  if (isChange) {
    form =
      <form
        className="contact-form"
        onSubmit={handleSubmit}
        onChange={handleChange}
        method="post"
        encType="text/plain"
      >
        <h1 className="contact-form-title">
          <FormattedMessage id="home.form-title" />
        </h1>
        <label className="label-style label-title">
          <span className="span-title"><FormattedMessage id="home.form-select-title" /></span>
          <div className="select-wrapper">
            <select className="select-css" name="optionSelect">
              {options.map((option, i) => {
                return (
                  <FormattedMessage key={i} id={`home.form-select-option-${i + 1}`}>
                    {text => <option value={option}>{text}</option>}
                  </FormattedMessage>
                )
              })}
            </select>
            <span className="icon icon_angle-down" />
          </div>
        </label>
        <label className="label-style label-text">
          <span className="span-text"><FormattedMessage id="home.form-text-1" /></span>
          <FormattedMessage id="placeholder.type">
            {
              text =>
                <input
                  className="input-style"
                  type="text"
                  name="name"
                  placeholder={text}
                  required
                />
            }
          </FormattedMessage>
        </label>
        <label className="label-style label-text">
          <span className="span-text"><FormattedMessage id="home.form-text-2" /></span>
          <FormattedMessage id="placeholder.type">
            {
              text =>
                <input
                  className="input-style"
                  type="email"
                  name="mail"
                  placeholder={text}
                  required
                />
            }
          </FormattedMessage>
        </label>
        <button className="button" type="submit">
          <FormattedMessage id="home.form-button" />
        </button>
      </form>
  }

  if (isSuccess) {
    form =
      <div className="success">
        <h1 className="success-title">
          <FormattedMessage id="home.form-title" />
        </h1>
        <p className="success-text">
          <FormattedMessage id="home.form-success" />
        </p>
      </div>
  }

  return (
    <div id="home">
      <header id="masthead">
        <div className="container">
          <div className="logo" />
        </div>
      </header>

      <section id="intro">
        <div className="animation-container">
          <img className="intro-image" src={intro} />
        </div>
        <div className="half-container left">
          <div className="intro-info">
            <h1 className="title">
              <MarkdownMessage id="home.intro-title" />
            </h1>
            <p className="text">
              <FormattedMessage id="home.intro-text" />
            </p>
            <a className="button" href="#info">
              <FormattedMessage id="home.intro-button" />
            </a>
          </div>
        </div>
      </section>

      <section id="cards">
        <div className="container">
          <h1 className="cards-title">
            <MarkdownMessage id="home.cards-title" />
          </h1>
          <div className="card-container">
            <article className="card">
              <div className="card-text">
                <span className="card-name"><FormattedMessage id="home.cards-card1-name" /></span>
                <p><FormattedMessage id="home.cards-card1-text" /></p>
              </div>
              <img className="card-image" src={analyze} alt="Analyze" />
            </article>
            <article className="card">
              <div className="card-text">
                <span className="card-name"><FormattedMessage id="home.cards-aggregate-name" /></span>
                <p><FormattedMessage id="home.cards-aggregate-text" /></p>
              </div>
              <img className="card-image" src={aggregate} alt="Aggregate" />
            </article>
            <article className="card">
              <div className="card-text">
                <span className="card-name"><FormattedMessage id="home.cards-setting-name" /></span>
                <p><FormattedMessage id="home.cards-setting-text" /></p>
              </div>
              <img className="card-image" src={setting} alt="Setting" />
            </article>
            <article className="card">
              <div className="card-text">
                <span className="card-name"><FormattedMessage id="home.cards-financing-name" /></span>
                <p><FormattedMessage id="home.cards-financing-text" /></p>
              </div>
              <img className="card-image image-align" src={financing} alt="Financing" />
            </article>
          </div>
        </div>
      </section>

      <section id="info">
        <div className="container">
          <div className="info-info">
            <h1 className="info-title">
              <MarkdownMessage id="home.info-title" />
            </h1>
            <div className="info-paragraph">
              <p className="info-text">
                <MarkdownMessage id="home.info-text-1" />
              </p>
              <p className="info-text">
                <FormattedMessage id="home.info-text-2" />
              </p>
            </div>
          </div>
          <div className="info-pdf">
            <article className="card">
              <div className="card-text">
                <p className="card-text-1">
                  <MarkdownMessage id="home.info-card-1" />
                </p>
                <p className="card-text-2">
                  <MarkdownMessage id="home.info-card-2" />
                </p>
              </div>
              <div className="card-button">
                <a className="button" href="archives/G-Volt_whitepaper.pdf" target="_blank" to="/">
                  <div className="button-image" />
                  <p><MarkdownMessage id="home.info-button" /></p>
                </a>
              </div>
            </article>
          </div>
        </div>
      </section>
      <section id="form">
        <div className="form-triangle" />
        <div className="form-banner" />
        <div className="container">
          <div className="right">
            <div className="form-logo" />
            {form}
          </div>
        </div>
      </section>

      <section id="footer">
        <footer className="footer-style">
          <div className="container">
            <div className="left">
              <div className="logo" />
            </div>
          </div>
        </footer>
      </section>
    </div>
  )
}

export default Home
